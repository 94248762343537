
.c-color--invert,
.c-color--white {
	color: $color-base-invert;
}
.c-color--default {
	color: $color-base;
}


h1, h2, h3, h4, h5, h6 {
	.o-svg-icon {
		vertical-align: middle;
		margin-right: 0.75em;
		color: color(primary);

		&--right {
			margin-right: 0;
			margin-left: 0.75em;
		}
	}
	[class*="c-link-cta"] {
		margin-left: 0.75em;
	}
}

[class*="c-title"] {
	font-weight: $font-weight-accent;
	color: $color-accent;
	font-size: font-size(h4);
	line-height: $line-height-medium;
	@include media-breakpoint-up(md) {
		font-size: font-size(h3);
	}

	strong {
		font-weight: $font-weight-accent;
	}
	a {
		font-weight: $font-weight-accent;
	}

	.c-color--invert &,
	.c-color--white & {
		color: $color-accent-invert;
	}
	.c-color--default & {
		color: $color-accent;
	}
}
.c-title {
	&--extra-small {
		font-size: font-size(h6);
		@include media-breakpoint-up(md) {
			font-size: font-size(h5);
		}
	}
	&--small {
		font-size: font-size(h5);
		@include media-breakpoint-up(md) {
			font-size: font-size(h4);
		}
	}
	&--large {
		font-size: font-size(h4);
		@include media-breakpoint-up(sm) {
			font-size: font-size(h3);
		}
		@include media-breakpoint-up(lg) {
			font-size: font-size(h2);
			line-height: $line-height-large;
		    letter-spacing: -0.025em;
		}
	}
	&--extra-large {
		font-size: font-size(h3);
		@include media-breakpoint-up(sm) {
			font-size: font-size(h2);
			line-height: $line-height-large;
		    letter-spacing: -0.025em;
		}
		@include media-breakpoint-up(lg) {
			font-size: font-size(h1);
		}
	}
}

.c-caption {
	font-size: font-size(03);
}

.c-note {
	color: $color-base-lighter;
	font-size: font-size(02);

	.c-color--invert &,
	.c-color--white & {
		color: $color-base-invert-lighter;
	}
	.c-color--default & {
		color: $color-base-lighter;
	}
}

.c-error {
	color: color(extra, error);
}

.c-pre {
	@extend pre;
	display: block;
}
.c-code {
	@extend code;
}
.c-pre,
.c-code {
	&--large {
		font-size: font-size(h5);
	}
	&--wide {
		letter-spacing: 0.25em;
	}
}
