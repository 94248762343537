
.c-listing {
	margin-left: - $grid-gutter / 4;
	margin-right: - $grid-gutter / 4;

	.c-listing__item {
		display: flex;
		padding-left: $grid-gutter / 4;
		padding-right: $grid-gutter / 4;

		.c-card {
			width: 100%;
		}
	}

	&.c-listing--compact {
		margin-left: - $grid-gutter / 2;
		margin-right: - $grid-gutter / 2;
		border-top: rem(2px) solid $color-border-base;

		.c-listing__item {
			padding-left: 0;
			padding-right: 0;

			.c-card {
				margin-bottom: 0;
			}
		}

		.c-modal & {
			@include media-breakpoint-up(lg) {
				margin-left: - $grid-gutter;
				margin-right: - $grid-gutter;

				.c-card__body {
					padding-left: $grid-gutter;
					padding-right: $grid-gutter;
				}
			}
		}
	}
}

.c-listing__header {
	margin-bottom: $spacer-base - $spacer-base-small;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin-top: $spacer;
		margin-bottom: $spacer;
	}
	p {
		color: $color-base-light;

		.c-color--invert {
			color: $color-base-invert-light;
		}
		.c-color--default {
			color: $color-base-light;
		}
	}
}

.c-listing__footer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-bottom: $spacer-base - $spacer;

	[class^=o-col], [class*=" o-col"] {
		display: flex;
		justify-content: center;
	}
	.o-col {
		flex-basis: 10%;
		flex-grow: 1;
	}
	.c-pagination {
		margin-bottom: 0;
	}
}


@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@include media-breakpoint-up( #{$breakpoint-key} ) {
		@each $spacer-key, $spacer-value in $spacers {
			.c-listing--block\@#{$breakpoint-key} {
				margin-bottom: $spacer-base - $spacer-base-small;

				.c-card {
					margin-bottom: $spacer-base-small;
				}
				.c-card__body {
					flex-direction: row;
					align-items: center;
					padding-top: $spacer;
					padding-bottom: $spacer;
				}
				.c-card__content {
					flex-grow: 1;
					display: flex;
					align-items: center;
					margin-right: $grid-gutter / 2;
				}
				.c-card__footer {
					flex-shrink: 0;
					flex-direction: row-reverse;
					margin-top: 0;
					margin-left: auto;
					padding-right: rem(20px);

					[class*="c-link-cta"] {
						margin-top: $spacer;
						margin-bottom: $spacer;
					}
				}
				.c-card__title {
					flex-shrink: 0;
					flex-basis: 30%;
					margin-top: $spacer;
					margin-bottom: $spacer;
					font-size: font-size(05);
				}
				.c-card__info {
					flex-grow: 1;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					margin-top: $spacer;
					margin-bottom: $spacer;
					margin-left: $grid-gutter / 2;

					p {
						flex-basis: 25%;
						margin-top: $spacer;
						margin-bottom: $spacer;
						padding-right: $grid-gutter / 2;
					}
				}
				.c-card__content--1col {
					.c-card__title {
						flex-basis: 100%;
					}
				}
				.c-card__actions {
					top: 50%;
					margin-top: - $popup-header-height-small / 2;

					.c-card__actions-toggle {
						&:before {
							display: none;
						}
						.o-svg-icon {
							transform: rotate(90deg);
						}
					}
				}
				.c-card__toggle {
					position: relative;
					top: auto;
					right: auto;
					margin-right: -0.75rem;
				}
				.c-form-element--checkbox {
					top: 50%;
					margin-top: - rem(14px);
				}
				.c-card--link {
					.c-form-element--checkbox {
						top: 0;
						margin-top: 0;

						label {
							&:before {
								top: 50%;
								transform: translateY(-50%);
							}
						}
					}
				}
				.c-listing__item--skeleton {
					.react-loading-skeleton {
						min-height: rem(50px);
					}
				}
			}
		}
	}
}

.c-listing__item--skeleton {
	.react-loading-skeleton {
		display: block;
		min-height: rem(160px);
	}

	.c-theme--dark & {
		.react-loading-skeleton {
			background-color: rgba($color-background-invert, .2);
			background-image: linear-gradient( 90deg, transparent, rgba($color-background-dark, .6), transparent );
		}
	}
}
