.c-site-footer {
	position: relative;
	background: color(neutral, 300);

	.c-theme--dark & {
		background: color(neutral, 800);
	}

	> [class*="o-container"] {
		position: relative;
		z-index: 4;
	}
}

.c-site-footer__content {
	padding-top: $spacer-base-large;
	@include media-breakpoint-up(md) {
		padding-top: $spacer-section-small;
	}
}

.c-site-footer__wrapper {
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: center;
	padding-top: $spacer-base-large;
	padding-bottom: $spacer-base-large - $spacer-base;
	@include media-breakpoint-up(md) {
		flex-direction: row;
		text-align: left;
		padding-top: $spacer-base;
		padding-bottom: $spacer-base;
	}

	.c-site-logo {
		flex-shrink: 0;
		margin-bottom: $spacer-base;
		@include media-breakpoint-up(md) {
			margin-bottom: 0;
			margin-right: $spacer-base;
		}

		&.c-site-logo--symbol {
			width: $logo-symbol-width-ratio * rem(30px);
		}
		&.c-site-logo--basic {
			width: $logo-basic-hor-width-ratio * rem(30px);
		}
	}
	.c-social-list {
		margin-bottom: $spacer-base-small;
		@include media-breakpoint-up(md) {
			flex-shrink: 0;
			margin-bottom: 0;
			margin-left: auto;
		}

		a {
			@include link-basic;
		}
	}
}

.c-site-footer__info {
	position: relative;
	top: 1px;

	@include media-breakpoint-up(md) {
		flex-grow: 1;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: $spacer-base-small;
		margin-right: $spacer-base;
	}

	p,
	ul {
		margin-bottom: $spacer-base-small;
		font-size: font-size(03);
		color: $color-base-light;
		@include media-breakpoint-up(md) {
			font-size: font-size(02);
		}
	}
	a {
		@include link-basic;
	}
	p {
		margin-right: $spacer-base;
	}
	ul {
		@include media-breakpoint-up(md) {
			display: flex;
			flex-wrap: wrap;

			li {
				position: relative;

				&:before {
					content: "";
					display: inline-block;
					margin: 0 $spacer-base-small;
					width: rem(4px);
					height: rem(4px);
					border-radius: 50%;
					background: rgba(color(primary), 0.5);
					vertical-align: middle;
				}

				&:first-child {
					&:before {
						display: none;
					}
				}
			}
		}
	}

	.c-color--invert & {
		p {
			color: $color-base-invert-light;
		}
	}
}
