html {
	overflow-y: visible;
	overflow-y: inherit;
	&.is-ticker {
		overflow-y: hidden;
	}

	@media (min-width: 1500px) {
		font-size: 18px;
	}
	@media (min-width: 1800px) {
		font-size: 20px;
	}
	@media (min-width: 2000px) {
		font-size: 22px;
	}
	@media (min-width: 2200px) {
		font-size: 24px;
	}
	@media (min-width: 2400px) {
		font-size: 26px;
	}
}
body {
	position: relative;
	overflow-y: scroll;
	font-size: font-size(04);
}

.is-ticker {
	body {
		overflow-y: hidden;
	}
	overflow-y: hidden;
}

html,
body {
	background-color: $color-background-base;
}

embed,
iframe,
object,
video {
	width: 100%;
}
