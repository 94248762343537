
.c-tabs-content {
	margin-bottom: $spacer-base;

	.c-tabs-item {
		display: none;

		&.is-active {
			display: block;
			animation: tabsContentActive $animation-speed-faster;
		}
	}
}

.c-tabs-nav {
	margin-bottom: $spacer-base;
}

.c-tabs-nav-menu--dropdown {
	margin-left: - $grid-gutter / 2;
	margin-right: - $grid-gutter / 2;
	padding-left: $grid-gutter / 2;
	padding-right: $grid-gutter / 2;
	padding-top: $spacer-base;
	padding-bottom: 0.001rem;
	background: $color-background-light2;

	.c-theme--dark & {
		background: $color-background-primary-dark;
	}
}

.c-tabs-nav-menu--horizontal {
	position: relative;
	display: flex;
	margin-left: - $grid-gutter / 2;
	margin-right: - $grid-gutter / 2;
	margin-bottom: $spacer-base-large;

	&:before {
		content: '';
		position: absolute;
		left: $grid-gutter / 2;
		right: $grid-gutter / 2;
		top: 100%;
		height: rem(1px);
		background: $color-border-base;
	}

	a {
		@include link-cta-light;
		text-align: center;
		align-items: stretch;

		span {
			display: flex;
			align-items: center;
			padding-top: $spacer;
			padding-bottom: $spacer;

			&:before {
				left: 50%;
				right: auto;
				max-width: 100%;
				width: rem(30px);
				transform: translateX(-50%);
			}
		}
	}
	li {
		display: flex;
		padding-left: $grid-gutter / 2;
		padding-right: $grid-gutter / 2;

		&.is-selected {
			a {
				color: $color-links;

				span {
					&:before {
						background-image: linear-gradient(to left, rgba(color(secondary), .8) 50%, rgba(color(primary), 1) 50%);
						background-position-x: 100%;
					}
				}
			}
		}
	}

	.c-color--invert & {
		&:before {
			background: $color-border-invert;
		}
	}
	.c-color--default & {
		&:before {
			background: $color-border-base;
		}
	}
}
