
.c-add {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: $spacer-base;
	padding: $spacer-base ($grid-gutter / 2);
	padding-bottom: 0.001rem;
	border-radius: $border-radius-large;
	border: 2px dashed $color-border-base-dark;
	transition: all $transition-speed-slower;
	@include media-breakpoint-up(sm) {
		padding: $spacer-base-large $grid-gutter;
		padding-bottom: $spacer-base-large - $spacer-base;
	}

	a {
		position: relative;
		z-index: 10;
	}
	.c-add__link {
		position: static;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 5;
		}
	}

	&.c-add--link {
		&:hover {
			border-color: $color-links;

			.c-add__icon {
				.o-svg-icon {
					transform: scale(1.1);
				}
			}
		}
	}

	.c-theme--dark & {
		border-color: $color-border-invert;
	}
}

.c-add--selected {
	display: block;
	border-width: 1px;
	border-style: solid;
	text-align: center;
	padding-top: 1.875rem;
	padding-right: 1.875rem;
	padding-bottom: 1rem;
	padding-left: 1.875rem;

	@include media-breakpoint-up(sm) {
		padding-bottom: 1.875rem;
	}

	.c-button--stroke {
		.c-theme--dark & {
			color: color( extra, white );
			border-color: color( extra, white );

			&:hover {
				border-color: color( secondary );
			}
		}
	}
}

.c-add--light {
	color: color( extra, black );
	background-color: color( extra, white );

	.c-button--stroke.c-button--stroke,
	.c-link-cta-basic.c-link-cta-basic {
		color: color( extra, black );
	}

	.c-button--stroke.c-button--stroke {
		border-color: color( secondary );

		&:hover {
			color: color( primary );
			border-color: color( primary );
		}
	}

	.c-link-cta-basic.c-link-cta-basic {
		span:before {
			background-image: linear-gradient(to left, transparent 50%, rgba(color(primary), 1) 50%);
		}
	}
}

.c-add--dark {
	background-color: color( neutral, 800 );

	.c-button--stroke,
	.c-link-cta-basic {
		color: color( extra, white );
	}

	.c-button--stroke {
		border-color: color( extra, white );

		&:hover {
			color: color( extra, white );
			border-color: color( secondary );
		}
	}

	.c-link-cta-basic {
		span:before {
			background-image: linear-gradient(to left, transparent 50%, rgba(color(secondary), 1) 50%);
		}
	}
}

.c-add__buttons-group {
	@include media-breakpoint-up(sm) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	> a {
		display: block;
		margin-top: .625rem;
		margin-bottom: .625rem;

		@include media-breakpoint-up(sm) {
			justify-content: center;
			width: calc( 50% - .625rem );
			flex: 0 0 calc( 50% - .625rem );
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}

.c-add__icon {
	flex-shrink: 0;
	align-self: flex-start;
	width: 100%;
	margin-bottom: $spacer-base-small;
	margin-right: $grid-gutter / 2;
	line-height: 1;
	font-size: 2rem;
	@include media-breakpoint-up(sm) {
		width: auto;
		margin-right: $grid-gutter;
		font-size: 3rem;
		margin-bottom: $spacer-base;
	}

	img {
		height: 6.25rem;
	}

	.o-svg-icon {
		transition: transform $transition-speed-slower;
	}

	.c-add--selected & {
		margin-right: 0;
		margin-bottom: 1.875rem;
	}
}

.c-add__desc {
	font-size: font-size(02);
	color: $color-base-lighter;

	.c-color--invert &,
	.c-color--white & {
		color: $color-base-invert-lighter;
	}
	.c-color--default & {
		color: $color-base-lighter;
	}
}

.c-add__body {
	margin-bottom: $spacer-base - $spacer;

	p {
		margin-bottom: $spacer;
	}
}
