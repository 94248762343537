@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.o-container--ticker-dashboard {
	background: $color-background-invert;
	min-height: 100vh;

	footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		left: 0;
		padding-bottom: rem(30px);
		@include media-breakpoint-up(md) {
			padding-bottom: 0;
			padding-right: rem(80px);
		}
		@include media-breakpoint-up(lg) {
			padding-right: rem(20px);
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: rem(15px);
			height: rem(30px);
			background: transparent
				url("../../assets/images/logo/logo-basic--hor--invert.svg") center
				center no-repeat;
			background-size: contain;
			@include media-breakpoint-up(md) {
				top: rem(15px);
				left: auto;
				right: rem(20px);
				height: auto;
				width: rem(100px);
				background-position: right center;
			}
		}

		img {
			width: rem(100px);
			margin: 0 rem(10px);
		}
		.c-site-footer__info {
			p {
				font-size: 1.25em;
				color: $color-accent-invert;
			}
		}
	}
}
.ticker-app {
	display: flex;
	flex-flow: column;
	height: 100%;
	margin-bottom: rem(90px);
	background-color: $color-background-invert;
	padding: rem(20px) 0;
	text-align: center;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	.paused {
		max-width: rem(750px);
		margin-left: auto;
		margin-right: auto;
		margin-top: rem(10px);
		padding: $spacer;
		border-radius: $border-radius-large + rem(2px);
		border: rem(1px) solid color(extra, paused);

		.c-alert {
			margin-bottom: 0;
		}

		pre {
			margin-top: $spacer-base;
			padding-left: rem(20px);
			padding-right: rem(20px);
			font-family: $font-family-base;
			font-weight: $font-weight-base;
			color: $color-base-invert;
			font-size: rem(16px);
			text-align: left;
			background: transparent;
			border: none;
			@include media-breakpoint-up(md) {
				padding-left: rem(63px);
			}
		}
	}
	.units {
		max-width: rem(1024px);
		margin: 0 auto;

		@media (max-width: 1124px) {
			margin: 0 auto 0 auto;
		}

		.count {
			font-size: rem(120px);
			text-align: center;
			color: $color-accent-invert;
			@media (max-width: 1124px) {
				font-size: 13vw;
			}
			@media (max-height: 500px) {
				font-size: 10vw;
				margin-top: -50px;
			}
		}
	}

	.gauge {
		position: Relative;
		text-align: Center;
		overflow: Hidden;
		cursor: Default;
		display: inline-block;
		padding: 0 rem(25px);
		@media (max-width: 1200px) {
			padding: 0 rem(5px);
		}
		@media (max-width: 600px) {
			padding: 0 rem(5px);
		}
		// svg {

		// }
		.gauge-label {
			font-size: rem(36px);
			transform: translate(0, 10px);
			@media (max-width: 1200px) {
				font-size: rem(36px);
			}
			@media (max-width: 600px) {
				font-size: rem(25px);
			}
		}
		.gauge-sublabel {
			font-size: rem(14px);
			transform: translate(0, 60px);
			@media (max-width: 1200px) {
				font-size: rem(13px);
				transform: translate(0, 60px);
			}
			@media (max-width: 600px) {
				font-size: rem(13px);
				transform: translate(0, 40px);
			}
		}
	}
}

.dataTables_scrollHead {
	table {
		margin-bottom: 0;
	}
}

.c-widget-tabs {
	> [class^="o-col"],
	> [class*=" o-col"] {
		padding-left: $grid-gutter / 2;
		padding-right: $grid-gutter / 2;
	}

	.c-tabs-wrapper {
		blox {
			overflow: visible;
		}
		.font-weight-default {
			font-weight: inherit;
		}
		.font-size-default {
			font-size: inherit;
		}
		.color-default {
			color: inherit;
		}

		.blox-slides {
			position: relative;

			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				z-index: 2;
				width: $grid-gutter / 4;
				background: rgb(255, 255, 255);
				background: linear-gradient(
					90deg,
					rgba($color-background-base, 1) 0%,
					rgba($color-background-base, 0) 100%
				);
				pointer-events: none;
			}
			&:before {
				left: -$grid-gutter / 4;
			}
			&:after {
				right: -$grid-gutter / 4;
				transform: rotate(180deg);
			}
		}
		.container,
		.container.spacing-none {
			position: relative;
			padding: 0 !important;
			margin-bottom: $spacer-base;

			&:before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: 100%;
				margin-top: -$spacer;
				height: rem(1px);
				background: $color-border-base;
			}
		}
		.column-set {
			position: relative;
			display: flex;
			margin-left: -$grid-gutter / 4;
			margin-right: -$grid-gutter / 4;
			padding-bottom: $spacer;
			font-family: $font-family-base;
			width: auto !important;
			overflow: auto;
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				width: 0 !important;
				height: 0 !important;
				background: transparent !important;
			}

			.column,
			.column.spacing-none {
				@include link-cta-light;
				flex-grow: 0 !important;
				text-align: center;
				align-items: stretch;
				padding-left: $grid-gutter / 4;
				padding-right: $grid-gutter / 4;
				background: transparent !important;

				.text-block {
					@extend span;
					display: flex;
					align-items: center;
					padding-top: $spacer;
					padding-bottom: $spacer;
					line-height: $line-height-base;
					overflow: visible;

					&:before {
						left: 50%;
						right: auto;
						max-width: 100%;
						width: rem(30px);
						transform: translateX(-50%);
					}
				}

				&.is-selected {
					color: $color-links;

					.text-block {
						&:before {
							background-position-x: 0;
						}
					}
				}
			}
		}
	}
}
