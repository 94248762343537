/* =============================================================================
   COLORS (settings/_colors.scss)
   ========================================================================== */

/*
 * Colors that are most represented in the design, every project must contain at
 * least one primary color.
 */

$color-primary: (
	primary: (
		default: rgb(4, 84, 147),
		/* #045493 */ blue: rgb(4, 84, 147),
		/* #045493 */
	),
) !default;

/*
 * Colors that complement primary palette, every project must contain at least
 * one secondary color.
 */

$color-secondary: (
	secondary: (
		default: rgb(0, 169, 224),
		/* #00A9E0 */ blue: rgb(0, 169, 224),
		/* #00A9E0 */
	),
) !default;

/*
 * Neutral colors complementing first two palettes.
 */

$color-neutral: (
	neutral: (
		default: #888,
		100: #f6f6f6,
		200: #e5e5e5,
		300: #ccc,
		400: #bebaba,
		500: #888,
		600: #666,
		700: #444,
		800: #2e2e2e,
		900: #121212,
	),
) !default;

/**
 * Colors used on notifications or interactive elements/forms with dynamic
 * states.
 */

$color-extra: (
	extra: (
		black: rgb(0, 0, 0), /* #000000 */
		white: rgb(255, 255, 255), /* #ffffff */
		links: rgb(0, 169, 224), /* #00A9E0 */
		success: rgb(19, 171, 0), /* #13AB00 */
		info: rgb(41, 127, 203), /* #297FCB */
		warning: rgb(237, 154, 0), /* #ED9A00 */
		error: rgb(206, 0, 0), /* #CE0000 */
		paused: rgb(95, 39, 225), /* #5f27e1 */
	),
) !default;

/*
 * Neutral colors complementing first two palettes.
 */

$color-brand: (
	brand: (
		facebook: rgb(59, 89, 152),
		/* #3b5998 */ twitter: rgb(29, 161, 242),
		/* #1da1f2 */ linkedin: rgb(0, 119, 181),
		/* #0077b5 */ googleplus: rgb(221, 75, 57),
		/* #dd4b39 */ youtube: rgb(255, 0, 0),
		/* #ff0000 */ reddit: rgb(255, 69, 0),
		/* #ff4500 */ pinterest: rgb(189, 8, 28),
		/* #bd081c */ instagram: rgb(64, 93, 230),
		/* #405de6 */ whatsapp: rgb(7, 94, 84),
		/* #075e54 */ messenger: rgb(0, 132, 255),
		/* #0084ff */ tumblr: rgb(53, 70, 92),
		/* #35465c */ snapchat: rgb(255, 252, 0),
		/* #fffc00 */ flickr: rgb(0, 99, 220),
		/* #0063dc */ myspace: rgb(0, 0, 0),
		/* #000000 */ soundcloud: rgb(255, 136, 0),
		/* #ff8800 */ wikipedia: rgb(99, 100, 102),
		/* #636466 */ pocket: rgb(239, 64, 86),
		/* #ef4056 */ kindle: rgb(255, 153, 0),
		/* #ff9900 */ hackernews: rgb(255, 64, 0),
		/* #ff4000 */ mailru: rgb(22, 141, 226),
		/* #168de2 */ odnoklassniki: rgb(237, 129, 43),
		/* #ed812b */ vkontakte: rgb(69, 102, 142),
		/* #45668e */ embed: rgb(242, 101, 34),
		/* #f26522 */ rss: rgb(242, 101, 34),
		/* #f26522 */
	),
) !default;

/**
 * Merge all color maps into one.
 */

@function map-collect($maps...) {
	$collection: ();
	@each $map in $maps {
		$collection: map-merge($collection, $map);
	}
	@return $collection;
}
$colors: map-collect(
	$color-primary,
	$color-secondary,
	$color-neutral,
	$color-extra,
	$color-brand
) !default;

/**
 * Set a specific jump point for requesting color jumps.
 */

$color-shade-percentage: 10% !default;

/**
 * Colors used for base things such as base text color and links color.
 *
 * Don't use `color()` mixin in following variables, they're not available in
 * this (settings) layer.
 */

$color-accent: color(extra, black) !default;
$color-base: rgba(color(extra, black), 0.85) !default;
$color-base-light: rgba(color(extra, black), 0.6) !default;
$color-base-lighter: rgba(color(extra, black), 0.45) !default;

$color-accent-invert: color(extra, white) !default;
$color-base-invert: rgba(color(extra, white), 0.85) !default;
$color-base-invert-light: rgba(color(extra, white), 0.65) !default;
$color-base-invert-lighter: rgba(color(extra, white), 0.45) !default;

$color-links-lighter: color-lighten(extra, links) !default;
$color-links: color(extra, links) !default;
$color-links-darker: color-darken(extra, links) !default;

$color-border-base: rgba(100, 100, 100, 0.15) !default;
$color-border-base-dark: rgba(100, 100, 100, 0.25) !default;
$color-border-base-darker: rgba(0, 0, 0, 0.3) !default;
$color-border-base-darkest: rgba(0, 0, 0, 0.6) !default;

$color-border-invert: rgba(200, 200, 200, 0.2) !default;
$color-border-invert-light: rgba(200, 200, 200, 0.4) !default;
$color-border-invert-lighter: rgba(255, 255, 255, 0.4) !default;
$color-border-invert-lightest: rgba(255, 255, 255, 0.75) !default;

$color-background-base: color(extra, white);
$color-background-invert: color(extra, black);
$color-background-dark: color(neutral, 800);
$color-background-primary: color(primary);
$color-background-primary-dark: darken(color(primary), 15%);
$color-background-secondary: color(secondary);
$color-background-light: color(neutral, 100);
$color-background-light2: lighten(color(secondary), 54%);
$color-background-light-invert: color(neutral, 900);

$color-background-dash-light: rgba(199, 199, 199, 1);
$color-background-dash-dark: rgba(36, 36, 36, 1);
