.c-card {
	position: relative;
	margin-bottom: $spacer-base;

	form {
		margin-bottom: 0;
	}

	&.c-card--link,
	&.c-card--link-main {
		.c-card__checkbox {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			.c-form-element__field {
				position: static;
			}
			label {
				padding: 0;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 5;
			}
		}
		a,
		.c-card__actions {
			z-index: 10;
		}
	}

	&.c-card--link-main {
		.c-card__link-main {
			position: static;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
		a,
		.c-card__checkbox,
		.c-card__actions {
			z-index: 10;
		}
	}
}

.c-card__header {
	margin-bottom: $spacer-base-small;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.c-card__title,
	.c-card__headline,
	.c-card__desc,
	.c-card__info,
	p {
		margin-bottom: $spacer;
	}
}

.c-card__body {
	display: flex;
	flex-direction: column;
	height: 100%;

	a {
		position: relative;
	}
	.c-card__checkbox {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;

		.c-form-element__field,
		label {
			margin: 0;

			&:before {
				top: rem(5px);
				left: rem(5px);
			}
		}
	}
	.c-card__actions {
		position: absolute;
		top: 0;
		right: 0;

		&.has-popup-open,
		.has-popup-open & {
			z-index: 100;
		}
	}
	.c-card__link-hidden {
		opacity: 0;
		visibility: hidden;
		transform: translateY(0.25em);
		transition: all $transition-speed;

		&.has-popup-open,
		.has-popup-open &.c-card__actions {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}

	&:hover {
		.c-card__link-hidden {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
		.c-card__footer {
			[class*="c-link-cta"] {
				&.c-card__link-main {
					@include link-cta-basic;
				}
			}
		}
	}
}

.c-card__title {
	@include word-wrap;
	margin-bottom: $spacer-base-small;
	font-weight: $font-weight-accent;
	color: $color-accent;
	line-height: $line-height-medium;
	font-size: font-size(h5);

	strong {
		font-weight: $font-weight-accent;
	}

	a {
		@include link-basic;
		font-weight: $font-weight-accent;
	}

	.c-color--invert &,
	.c-color--white & {
		color: $color-accent-invert;
	}
	.c-color--default & {
		color: $color-accent;
	}

	&.c-card__title--light {
		color: $color-base-light;

		a {
			@include link-basic-light;
		}

		.c-color--invert &,
		.c-color--white & {
			color: $color-base-invert-light;
		}
		.c-color--default & {
			color: $color-base-light;
		}
	}
}
.c-card__headline {
	margin-bottom: $spacer-base-small;
	font-size: font-size(05);
	color: $color-base-light;

	a {
		@include link-basic;
		font-weight: $font-weight-accent;
	}

	.c-color--invert & {
		color: $color-base-invert-light;
	}
}
.c-card__desc {
	margin-bottom: $spacer-base-small;
	font-size: font-size(03);
	color: $color-base-light;

	p {
		margin-bottom: $spacer;
	}
	.c-color--invert & {
		color: $color-base-invert-light;
	}
	.c-color--default & {
		color: $color-base-light;
	}
}
.c-card__info {
	margin-bottom: $spacer-base-small;
	font-size: font-size(02);
	color: $color-base-lighter;

	p {
		margin-bottom: $spacer;
		line-height: $line-height-medium;
	}
	.c-color--invert & {
		color: $color-base-invert-lighter;
	}
	.c-color--default & {
		color: $color-base-lighter;
	}
}
.c-card__footer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: auto;
	margin-left: -$spacer-base-small;
	margin-right: -$spacer-base-small;

	a {
		margin-left: $spacer-base-small;
		margin-right: $spacer-base-small;
		margin-bottom: $spacer-base-small;
	}
}
.c-card__buttons {
	.c-form-element,
	.c-form-element__field,
	label {
		margin-bottom: 0;
	}
}
.c-card__toggle {
	position: absolute;
	top: 0;
	right: -0.75rem;
	z-index: 10;
}
.c-card__actions {
	width: 80%;

	.c-card__actions-toggle {
		position: absolute;
		top: 0;
		right: 0;
		width: rem(30px);
		height: $popup-header-height-small;
		display: flex;
		align-items: center;
		margin: 0;
		color: $color-accent;
		opacity: 0.3;
		cursor: pointer;
		transition: all $transition-speed;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: rem(40px);
			height: rem(25px);
		}

		&:hover {
			opacity: 0.85;
		}

		.c-color--invert & {
			color: $color-accent-invert;
		}
		.c-color--default & {
			color: $color-accent;
		}

		// &--wide {
		// 	width: rem(50px);
		// }
	}
	.c-popup {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		max-width: rem(280px);
	}

	&.has-popup-open,
	.has-popup-open & {
		.c-card__actions-toggle {
			z-index: 10;
			color: $color-accent-invert;
		}
	}
}

.c-card--block {
	.c-card__actions {
		position: relative;
		width: auto;

		.c-card__actions-toggle {
			position: relative;
		}
		.c-popup {
			min-width: rem(240px);
		}
	}
	.c-card__link-hidden {
		opacity: 0;
		visibility: hidden;
		transform: translateY(0.25em);
		transition: all $transition-speed;
	}

	&:hover {
		.c-card__link-hidden {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}

	&.has-popup-open,
	.has-popup-open & {
		.c-card__actions {
			z-index: 100;
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
		.c-card__link-hidden {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}
}

.c-card__load {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: rem(30px);
	border-top-left-radius: $border-radius-large;
	border-top-right-radius: $border-radius-large;
	overflow: hidden;
	pointer-events: none;

	.c-card__load-bar {
		display: block;
		width: 50%;
		height: rem(10px);
		background: color(extra, success);
		box-shadow: $shadow-green-level-6;
	}

	&.c-card__load--indeterminate {
		.c-card__load-bar {
			display: block;
			width: 50%;
			margin-top: rem(2px);
			height: rem(6px);
			background: color(extra, success);
			box-shadow: $shadow-green-level-6;
			border-radius: $border-radius-large;
			margin-left: -50%;
			animation: loadingBar $animation-speed infinite;
		}
	}
}

/* Card Sizes
   ========================================================================== */

[class*="c-card--bg"] {
	border-radius: $border-radius-large;
	transition: all $transition-speed;

	.c-card__body {
		padding: rem(25px) rem(30px) (rem(25px) - $spacer-base-small);
	}

	&.c-card--compact,
	&.c-card--compact.c-card--link,
	&.c-card--compact.c-card--link-main,
	&.c-card--compact.is-selected {
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border-radius: 0;
		box-shadow: none !important;

		.c-card__body {
			padding-left: $grid-gutter / 2;
			padding-right: $grid-gutter / 2;
		}

		&:hover {
			z-index: 10;
		}
	}
}

/* Card Styles
   ========================================================================== */

.c-card--bg-white {
	border: 2px solid $color-border-base;
	background: $color-background-base;
	box-shadow: $shadow-card;

	&.c-card--link,
	&.c-card--link-main {
		&:hover {
			box-shadow: $shadow-blue-level-6;
		}
	}
	&.is-highlighted {
		box-shadow: $shadow-blue-level-6;
	}
	&.is-open,
	&.has-popup-open {
		border-color: $color-border-base-dark;
	}
	&.is-selected {
		border: 2px solid color(secondary);
		background: $color-background-light2;
		box-shadow: $shadow-blue-level-6;

		&.c-card--compact {
			border-color: $color-border-base;
			background: rgba($color-background-secondary, 0.05);
		}
	}

	.c-theme--dark & {
		border-color: $color-border-invert;
		background: $color-background-dark;
		box-shadow: $shadow-level-5-invert;

		&.c-card--link,
		&.c-card--link-main {
			&:hover {
				border-color: $color-border-invert-light;
				box-shadow: $shadow-level-7-invert;
			}
		}
		&.is-highlighted {
			box-shadow: $shadow-level-7-invert;
		}
		&.is-open,
		&.has-popup-open {
			border-color: $color-border-invert-light;
		}
		&.is-selected {
			border-color: color(secondary);
			box-shadow: $shadow-level-7-invert;
			&:hover {
				border-color: color(secondary);
			}

			&.c-card--compact {
				border-color: $color-border-invert;
				background: $color-background-primary-dark;
			}
		}
	}
}

.c-card--bg-light {
	border: 2px solid transparent;
	background: $color-background-light;
	box-shadow: none;

	&.c-card--link,
	&.c-card--link-main {
		&:hover {
			box-shadow: $shadow-blue-level-6;
		}
	}
	&.is-highlighted {
		box-shadow: $shadow-blue-level-6;
	}
	&.is-open,
	&.has-popup-open {
		border-color: color(secondary);
	}
	&.is-selected {
		border: 2px solid color(secondary);
		background: $color-background-light;
		box-shadow: $shadow-blue-level-6;
	}

	.c-theme--dark & {
		background: $color-background-light-invert;

		&.c-card--link,
		&.c-card--link-main {
			&:hover {
				box-shadow: $shadow-level-7-invert;
			}
		}
		&.is-highlighted {
			box-shadow: $shadow-level-7-invert;
		}
		&.is-open,
		&.has-popup-open {
			border-color: color(secondary);
		}
		&.is-selected {
			border-color: color(secondary);
			background: $color-background-light-invert;
			box-shadow: $shadow-level-7-invert;
		}
	}
}

.c-card--bg-primary {
	border: 2px solid transparent;
	background: $color-background-primary;
	box-shadow: none;

	.c-theme--dark & {
		background: $color-background-primary;
	}
}

.c-card--bg-warning {
	border: 2px solid transparent;
	box-shadow: none;
	border-color: color(extra, warning);
}

.c-card--bg-error {
	border: 2px solid transparent;
	box-shadow: none;
	border-color: color(extra, error);
}

.c-card--bg-warning {
	border-color: color(extra, warning);

	&.is-open,
	&.has-popup-open,
	&.is-selected {
		border-color: color(extra, warning);
	}

	.c-theme--dark & {
		border-color: color(extra, warning);

		&.is-open,
		&.has-popup-open,
		&.is-selected {
			border-color: color(extra, warning);
		}
	}
}

/* Card Alerts
   ========================================================================== */

.c-card__alert {
	@extend .c-color--invert;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 50;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	align-content: center;
	text-align: center;
	padding: 0 ($grid-gutter / 2) 0.001rem;
	border-radius: $border-radius-large;
	background: rgba($color-background-dark, 0.96);
	overflow: auto;
	animation: cardAlertOpen $animation-speed-faster;

	p {
		margin: $spacer;
	}
	.c-card__alert-question {
		color: $color-accent-invert;
		margin: $spacer-base-small;
		font-size: font-size(04);
		font-weight: $font-weight-base;
	}
	.c-card__alert-options {
		flex-shrink: 0;

		.c-button {
			margin: $spacer;
		}
	}

	&.c-card__alert--error {
		background: rgba(color(extra, error), 0.96);

		.c-button.c-button--stroke.c-button--white {
			&:hover {
				color: color(extra, error);
			}
		}
	}
}
