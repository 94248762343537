
$horizontal-spacer-large: rem(80px) !default;

$header-height-mobile: rem(60px) !default;
$header-height: rem(80px) !default;
$header-height-sticky: rem(60px) !default;

$border-radius-small: rem(2px) !default;
$border-radius: rem(4px) !default;
$border-radius-large: rem(6px) !default;

$popup-header-height-small: rem(36px) !default;
$popup-header-height: rem(40px) !default;
$popup-header-height-large: rem(48px) !default;

/* logo width when height is 100 */
$logo-basic-hor-width-ratio: 424 / 100;
$logo-symbol-width-ratio: 100 / 100;
