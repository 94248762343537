
.c-site-nav {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1001;
	width: $hamburger-icon-layer-width + (2 * $hamburger-icon-padding-x);
	max-height: $header-height-mobile;
	border-bottom-right-radius: $border-radius-large;
	box-shadow: none;
	transition: all $transition-speed;
	@include media-breakpoint-up(md) {
		max-height: $header-height;
	}

	.c-site-logo {
		margin-left: $grid-gutter / 2;
		margin-right: $grid-gutter / 2;
		visibility: hidden;
		opacity: 0;
		transform: translateX(- $hamburger-icon-layer-width - (2 * $hamburger-icon-padding-x));
		transition: all $transition-speed-slower;

		&.c-site-logo--symbol {
			width: $logo-symbol-width-ratio * rem(24px);
			@include media-breakpoint-up(md) {
				width: $logo-symbol-width-ratio * rem(32px);
			}
		}
		&.c-site-logo--basic {
			width: $logo-basic-hor-width-ratio * rem(30px);
			@include media-breakpoint-up(md) {
				width: $logo-basic-hor-width-ratio * rem(35px);
			}
		}
	}

	&.has-popup-open {
		max-height: 100vh;
		width: calc(100% - 1.25rem);
		max-width: rem(550px);
		background: $color-background-secondary;
		box-shadow: $shadow-blue-level-6;

		.c-site-logo {
			visibility: visible;
			opacity: 1;
			transform: translateX(0);
		}
		.c-site-nav__header {
			width: auto;
			background-position-x: 0%;
		}
		.c-site-nav__popup {
			visibility: visible;
			opacity: 1;
			transform: translateY(0);
		}
	}

	.c-theme--dark & {
		&.has-popup-open {
			background: $color-background-primary-dark;
			box-shadow: $shadow-level-7-invert;
		}
	}
}

.c-site-nav__header {
	display: inline-flex;
	align-items: center;
	width: $hamburger-icon-layer-width + (2 * $hamburger-icon-padding-x);
	background: linear-gradient(to left, transparent 50%, rgba(255,255,255, 1) 50%);
	background-position: 99% 0;
	background-size: 210% 100%;
	background-repeat: no-repeat;
	border-bottom-right-radius: $border-radius-large;
	transition: all $transition-speed-slower;

	a {
		flex-shrink: 0;
	}
}

.c-site-nav-toggle {
	@extend a;
	position: relative;
	z-index: 10;
	display: flex;
	align-items: center;
	width: $hamburger-icon-layer-width + (2 * $hamburger-icon-padding-x);
	height: $header-height-mobile;
	font-size: $font-size-base;
	font-weight: $font-weight-accent;
	color: $color-base-light;
	border-bottom-right-radius: $border-radius-large;
	background: $color-background-secondary;
	background: linear-gradient(to left, color(secondary) 50%, color(primary) 50%);
	background-position: 99% 0;
	background-size: 210% 100%;
	background-repeat: no-repeat;
	border-bottom-right-radius: $border-radius-large;
	transition: all $transition-speed ease-in-out;
	cursor: pointer;
	@include media-breakpoint-up(md) {
		height: $header-height;
	}

	.c-site-nav-toggle__text {
		display: none;
	}
	.c-site-nav-toggle__icon {
		position: relative;
		order: -1;
		width: $hamburger-icon-layer-width + (2 * $hamburger-icon-padding-x);
		height: $header-height-mobile;
		margin-right: - $hamburger-icon-padding-x;

		span {
			position: absolute;
			top: 50%;
			left: 50%;
			width: ($hamburger-icon-layer-width / 2) - ($hamburger-icon-layer-width * 1 / 20);
			height: ($hamburger-icon-layer-width / 2) - ($hamburger-icon-layer-width * 1 / 20);
			border: $hamburger-icon-layer-height solid $hamburger-icon-layer-color-invert;
			border-radius: $hamburger-icon-layer-border-radius;
			transition: all $transition-speed;
			transform-origin: 0% 100%;

			&:nth-child(1),
			&:nth-child(3) {
				margin-left:  - ($hamburger-icon-layer-width / 2);
			}
			&:nth-child(2),
			&:nth-child(4) {
				margin-left: ($hamburger-icon-layer-width * 1 / 20);
			}
			&:nth-child(1),
			&:nth-child(2) {
				margin-top: - ($hamburger-icon-layer-width / 2);
			}
			&:nth-child(3),
			&:nth-child(4) {
				margin-top: ($hamburger-icon-layer-width * 1 / 20);
			}
		}
	}

	&:hover {
		background-position-x: 0;

		.c-site-nav-toggle__icon {
		}
	}

	&.is-active {
		background: $color-background-primary;
		background: linear-gradient(to left, $color-background-base 50%, color(primary) 50%);
		background-position: 99% 0;
		background-size: 210% 100%;
		background-repeat: no-repeat;

		.c-site-nav-toggle__icon {
			span {
				position: absolute;
				left: 50%;
				top: 50%;
				width: ($hamburger-icon-layer-width / 2) - ($hamburger-icon-layer-width * 1 / 20);
				height: $hamburger-icon-layer-height;
				margin: 0;
				border: 0;
				background: $hamburger-icon-layer-color;

				&:nth-child(1) {
					transform: translate($hamburger-icon-layer-height / 4, - 120%) rotate(-135deg);
				}
				&:nth-child(2) {
					transform: translate($hamburger-icon-layer-height / 4, - 50%) rotate(-45deg);
				}
				&:nth-child(3) {
					transform: translate(- $hamburger-icon-layer-height / 4, -120%) rotate(135deg);
				}
				&:nth-child(4) {
					transform: translate(- $hamburger-icon-layer-height / 4, -50%) rotate(45deg);
				}
			}
		}

		&:hover {
			background-position-x: 0;

			.c-site-nav-toggle__icon {
				span {
					background: $hamburger-icon-layer-color-invert;
				}
			}
		}
	}

	.c-theme--dark & {
		background-image: linear-gradient(to left, color(primary) 50%, color(secondary) 50%);

		&.is-active {
			background-image: linear-gradient(to left, $color-background-base 50%, color(secondary) 50%);
		}
	}
}

.c-site-nav__popup {
	visibility: hidden;
	opacity: 0;
	transform: translateY(-1rem);
	transition: all $transition-speed;
	padding-top: $spacer-base;
	padding-bottom: 0.001rem;
	padding-right: $grid-gutter / 2;
	padding-left: $grid-gutter / 2;
	@include media-breakpoint-up(sm) {
		padding-left: $horizontal-spacer-large;
	}
}

.c-site-nav__menu {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0;

	li {
		margin-bottom: $spacer-base;
	}
	a {
		display: flex;
		line-height: $line-height-medium;
		font-weight: $font-weight-accent;
		color: $color-accent-invert;
		font-size: font-size(04);

		.c-site-nav__menu-icon {
			flex-shrink: 0;
			margin-right: $grid-gutter / 2;

			.o-svg-icon {
				width: 2.25em;
				height: 2.25em;
			}
		}
		.c-site-nav__menu-text {
			span {
				display: block;
				font-size: font-size(03);
				font-weight: $font-weight-base;
				color: $color-base-invert;
			}
		}

		.c-color--invert &,
		.c-color--white & {
			color: $color-accent-invert;
		}
		.c-color--default & {
			color: $color-accent-invert;
		}
	}
}
