/* =============================================================================
   BUTTONS (settings/_buttons.scss)
   ========================================================================== */

/* Button Sizes
   ========================================================================== */

/* Button height sizes */
$button-height-small: $form-field-height-small !default;
$button-height: $form-field-height !default;
$button-height-large: $form-field-height-large !default;

/* Text sizes */
$button-text-size-small: rem(14px) !default;
$button-text-size: rem(16px) !default;
$button-text-size-large: rem(18px) !default;

/* Icon sizes */
$button-icon-size-small: rem(10px) !default;
$button-icon-size: rem(12px) !default;
$button-icon-size-large: rem(16px) !default;

/* Horizontal spacer sizes */
$button-padding-horizontal-small: rem(16px) !default;
$button-padding-horizontal: rem(20px) !default;
$button-padding-horizontal-large: rem(24px) !default;

/* Horizontal corner sizes */
$button-border-radius-small: $button-height-small / 2 !default;
$button-border-radius: $button-height / 2 !default;
$button-border-radius-large: $button-height-large / 2 !default;

/* Button border sizes */
$button-border-width: rem(2px) !default;



/* Style - General
   ========================================================================== */

/* Text weight */
$button-font-weight: $font-weight-accent !default;

/* Icon styles */
$button-icon-opacity: .7 !default;
$button-icon-opacity-hover: 1 !default;

/* Shadow styles*/
$button-shadow: none !default;
$button-shadow-hover: none !default;
$button-shadow-invert: none !default;
$button-shadow-invert-hover: none !default;

$button-stroke-shadow: none !default;
$button-stroke-shadow-hover: none !default;
$button-stroke-shadow-invert: none !default;
$button-stroke-shadow-invert-hover: none !default;



/* Style - Default
   ========================================================================== */

/* Fill */
$button-color: $color-accent-invert !default;
$button-color-hover: $color-accent-invert !default;
$button-color-invert: $color-accent-invert !default;
$button-color-invert-hover: $color-accent-invert !default;

$button-background: $color-background-dark !default;
$button-background-hover: color(primary) !default;
$button-background-invert: $color-background-dark !default;
$button-background-invert-hover: color(primary) !default;

$button-border-color: transparent !default;
$button-border-color-hover: transparent !default;
$button-border-color-invert: transparent !default;
$button-border-color-invert-hover: transparent !default;

/* Stroke */
$button-stroke-color: $color-accent !default;
$button-stroke-color-hover: color-darken(primary) !default;
$button-stroke-color-invert: $color-accent-invert !default;
$button-stroke-color-invert-hover: color(primary) !default;

$button-stroke-background: transparent !default;
$button-stroke-background-hover: transparent !default;
$button-stroke-background-invert: transparent !default;
$button-stroke-background-invert-hover: transparent !default;

$button-stroke-border-color: $color-border-base-dark !default;
$button-stroke-border-color-hover: color-lighten(primary) !default;
$button-stroke-border-color-invert: $color-border-invert-light !default;
$button-stroke-border-color-invert-hover: color(primary) !default;



/* Style - White
   ========================================================================== */

/* Fill */
$button-white-color: $color-accent !default;
$button-white-color-hover: $color-accent-invert !default;
$button-white-color-invert: $color-accent !default;
$button-white-color-invert-hover: $color-accent !default;

$button-white-background: $color-background-base !default;
$button-white-background-hover: color(primary) !default;
$button-white-background-invert: $color-background-base !default;
$button-white-background-invert-hover: color(secondary) !default;

$button-white-border-color: transparent !default;
$button-white-border-color-hover: transparent !default;
$button-white-border-color-invert: transparent !default;
$button-white-border-color-invert-hover: transparent !default;

/* Stroke */
$button-white-stroke-color: $color-accent-invert !default;
$button-white-stroke-color-hover: color-darken(primary) !default;
$button-white-stroke-color-invert: $color-accent-invert !default;
$button-white-stroke-color-invert-hover: color-darken(primary) !default;

$button-white-stroke-background: transparent !default;
$button-white-stroke-background-hover: $color-background-base !default;
$button-white-stroke-background-invert: transparent !default;
$button-white-stroke-background-invert-hover: $color-background-base !default;

$button-white-stroke-border-color: $color-background-base !default;
$button-white-stroke-border-color-hover: $color-background-base !default;
$button-white-stroke-border-color-invert: $color-background-base !default;
$button-white-stroke-border-color-invert-hover: $color-background-base !default;



/* Style - Primary
   ========================================================================== */

/* Fill */
$button-primary-color: $color-accent-invert !default;
$button-primary-color-hover: $color-accent-invert !default;
$button-primary-color-invert: $color-accent-invert !default;
$button-primary-color-invert-hover: $color-accent-invert !default;

$button-primary-background: color(secondary) !default;
$button-primary-background-hover: color(primary) !default;
$button-primary-background-invert: color(secondary) !default;
$button-primary-background-invert-hover: color-lighten(secondary) !default;

$button-primary-border-color: transparent !default;
$button-primary-border-color-hover: transparent !default;
$button-primary-border-color-invert: transparent !default;
$button-primary-border-color-invert-hover: transparent !default;

/* Stroke */
$button-primary-stroke-color: $color-accent !default;
$button-primary-stroke-color-hover: $color-accent-invert !default;
$button-primary-stroke-color-invert: $color-accent-invert !default;
$button-primary-stroke-color-invert-hover: $color-accent-invert !default;

$button-primary-stroke-background: transparent !default;
$button-primary-stroke-background-hover: color(primary) !default;
$button-primary-stroke-background-invert: transparent !default;
$button-primary-stroke-background-invert-hover: color(secondary) !default;

$button-primary-stroke-border-color: color(primary) !default;
$button-primary-stroke-border-color-hover: color(primary) !default;
$button-primary-stroke-border-color-invert: color(secondary) !default;
$button-primary-stroke-border-color-invert-hover: color(secondary) !default;



/* Style - Secondary
   ========================================================================== */

/* Fill */
$button-secondary-color: $color-accent !default;
$button-secondary-color-hover: $color-accent !default;
$button-secondary-color-invert: color(secondary) !default;
$button-secondary-color-invert-hover: $color-accent-invert !default;

$button-secondary-background: color(secondary) !default;
$button-secondary-background-hover: color-lighten(secondary) !default;
$button-secondary-background-invert: $color-background-base !default;
$button-secondary-background-invert-hover: color(secondary) !default;

$button-secondary-border-color: transparent !default;
$button-secondary-border-color-hover: transparent !default;
$button-secondary-border-color-invert: transparent !default;
$button-secondary-border-color-invert-hover: transparent !default;

/* Stroke */
$button-secondary-stroke-color: $color-accent !default;
$button-secondary-stroke-color-hover: color-darken(primary) !default;
$button-secondary-stroke-color-invert: $color-accent-invert !default;
$button-secondary-stroke-color-invert-hover: color-lighten(secondary) !default;

$button-secondary-stroke-background: transparent !default;
$button-secondary-stroke-background-hover: transparent !default;
$button-secondary-stroke-background-invert: transparent !default;
$button-secondary-stroke-background-invert-hover: transparent !default;

$button-secondary-stroke-border-color: color(secondary) !default;
$button-secondary-stroke-border-color-hover: color(primary) !default;
$button-secondary-stroke-border-color-invert: $color-border-invert-lightest !default;
$button-secondary-stroke-border-color-invert-hover: color(secondary) !default;



/* Other
   ========================================================================== */

/* mandatory */
$buttons-padding-x: 1rem !default;
$buttons-padding-y: .5rem !default;
$buttons-transition-speed: $transition-speed !default;
